/**
 * Title: Travel Business Portal New Version.
 * Description: This file all component compress--->file section name with numbering conversion.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
// import { Helmet } from "react-helmet";
// import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
import ScrollToTop from "react-scroll-to-top";
import { useContexts } from "../../hooks/useContexts";
import FifthSection from "../Components/FifthSection/FifthSection";
import FirstSection from "../Components/FirstSection/FirstSection";
import FourSection from "../Components/FourSection/FourSection";
import SecSection from "../Components/SecSection/SecSection";
import SevenSection from "../Components/SevenSection/SevenSection";
import SixSection from "../Components/SixSection/SixSection";
import TheSection from "../Components/TheSection/TheSection";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import "./Home.css";
const Home = () => {
  const { changeMode } = useContexts();
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Aotrek</title>
      </Helmet> */}
      {/* scroll bar up to top with right side */}
      <ScrollToTop smooth top="200" />
      {/* messenger chat with right side */}
      {/* <MessengerCustomerChat
        pageId="1609999342624642"
        appId="2809562682520774"
      /> */}
      {/* header section */}
      <Header />
      <main className={`${changeMode && 'bg_main_dark'}`}>
        {/* welcome aotrek first section */}
        <FirstSection />
        {/* who we are second section */}
        <SecSection />
        {/* our partners third section */}
        <TheSection />
        {/* our mission and vision section */}
        <FourSection />
        {/* our services fourth section */}
        <FifthSection />
        {/* our brands fifth section */}
        <SixSection />
        {/* contact us and get touch section */}
        <SevenSection />
      </main>
      {/* footer section */}
      <Footer />
    </>
  );
};

export default Home;
