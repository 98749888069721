/**
 * Title: Travel Business Portal New Version.
 * Description: Mission and vision.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React, { useState } from "react";
import { useContexts } from "../../../hooks/useContexts";
import first from "../../../images/4th_section_bg/first.png";
import sec from "../../../images/4th_section_bg/sec.png";
import "./FourSection.css";
const FourSection = () => {
  const [value, setValue] = useState({
    mission: true,
    vision: false,
  });
  const { mission, vision } = value;
  const { changeMode } = useContexts();
  // console.log(changeMode);
  return (
    <section
      id="about"
      className={`${changeMode && "bg_about"} bg_color_about pt-3 cus_pb`}
    >
      <div className="container position-relative">
        <div className="d-flex cursor_pointer my-4 py-2">
          <p
            className={`m-0 fw-bold d-inline text-light ${
              mission && "text_border"
            }`}
            onClick={() => setValue({ ...value, mission: true, vision: false })}
          >
            Our Mission
          </p>
          <p
            className={`m-0 fw-bold d-inline ms-4 text-light ${
              vision && "text_border"
            }`}
            onClick={() => setValue({ ...value, mission: false, vision: true })}
          >
            Our Vision
          </p>
        </div>
        {mission && (
          <div className="row align-items-center">
            <div className="col-md-4">
              <img
                draggable={false}
                className="img-fluid"
                src={first}
                alt="avatar"
              />
            </div>
            <div className="col-md-8 pe-lg-5 pt-lg-0 pt-md-0 pt-4">
              <h1 className="fw-bold text-light my-2 mb-3">MISSION</h1>
              <p className="pe-lg-3 text-light font_size justify_text">
                Travel Business Portal has been working for a long time to
                digitalize travel services and compact the services under an
                umbrella. so, for those who are interested in running their
                travel business, Travel Business Portal can serve them all their
                requirements.
              </p>
              <p className="pe-lg-3 text-light font_size justify_text">
                Aotrek Tourism Ltd. is the sole proprietor of travel business
                portal. The activities of the travel business portal are in a
                considerable range. Travel Business Portal (TBP) serves the
                customer to develop the travel agency in digital forms by
                developing an online travel agency portal as per their unique
                ideas and also serve the travel agency services as the customer
                can start their business from the day one.
              </p>
            </div>
          </div>
        )}
        {vision && (
          <div className="row align-items-center">
            <div className="col-md-7">
              <h1 className="fw-bold text-light">VISION</h1>
              <p className="text-light font_size justify_text">
                To be the leader of the future technology in the travel sector,
                TBP is working on it regularly and wishes the customers of TBP
                will have the best experience being with TBP.
              </p>
              <p className="text-light font_size justify_text">
                To support the customers, TBP is always open to their customers
                and always friends with them as the customers can feel the best
                technology partner and doing their business. TBP thinks that
                customers should focus on business and targeting the clients and
                TBP is working to support the inventory of the services and
                support the technology.
              </p>
              <p className="text-light font_size justify_text">
                TBP always focuses on increasing the inventory as much as
                possible. Not only focus on increasing the inventory, but also
                develop the services in the digital format as the customers can
                avail of the services in a very simple way.
              </p>
            </div>
            <div className="col-md-5 text_center_phn">
              <img draggable={false} className="w-75" src={sec} alt="avatar" />
            </div>
          </div>
        )}
        <div
          className={`position-absolute custom_positions ${
            changeMode && "custom_positions_dark"
          } d-lg-block d-md-block d-none`}
        >
          <h1 className="fw-bold">AOTREK</h1>
        </div>
      </div>
    </section>
  );
};

export default FourSection;
