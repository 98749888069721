/**
 * Title: Travel Business Portal New Version.
 * Description: Footer Section.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import "./Footer.css";
import bd from "../../../images/Flag/bd.png";
import ml from "../../../images/Flag/mal.png";
import ind from "../../../images/Flag/IN.png";
import nep from "../../../images/Flag/nep.png";
import com from "../../../images/Flag/com.png";
import uae from "../../../images/Flag/uae.png";
import thi from '../../../images/Flag/thi.png';

// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import { Autoplay, Pagination } from "swiper";
import { useContexts } from "../../../hooks/useContexts";

const Footer = () => {
  const length = new Date().toDateString().split(" ").length - 1;
  const year = new Date().toDateString().split(" ")[length];
  const country = [
    {
      flag: bd,
      name: "Bangladesh",
      address:
        "Travel Business Portal;Level 02, GA 25/4 - YB, Hasan Uddin Tower, Pragati Sharani, Shahjadpur, Gulshan, Dhaka - 1212.",
      cell: ["+88 0961 3809010", "+88 02-8899988"],
      tel: "",
      email: "info@aotrek.com",
    }
  ];
  const { changeMode } = useContexts();
  return (
    <footer>
      {/* big footer section */}
      <section
        className={`bg_footer ${
          changeMode && "bg_footers"
        } m-0 text_center_phns py-5`}
      >
        <Container>
          <section className="py-2">
            <h1
              className={`m-0 fw-bold get_touchs ${changeMode && "text-white"}`}
            >
              Address
            </h1>
          </section>

          <section>
            <div className="row align-items-start justify-content-lg-start">
              {country.map((country, i) => (
                <div key={i} className="font_size col-md-3 p-3">
                  {/* <div className="d-flex align-items-center justify-content-lg-start justify-content-md-start justify-content-start bg_shadow">
                    <img
                      src={country.flag}
                      width={35}
                      height={27}
                      alt="avatar"
                      draggable={false}
                    />
                    <p className={`m-0 ms-2 ${changeMode && "text-black"}`}>
                      <b>{country.name.toLocaleUpperCase()}</b>
                    </p>
                  </div> */}
                  <p
                    className={`mt-3 mb-2  ${
                      changeMode && "text-white"
                    }`}
                  >
                    {country.address}
                  </p>
                  <p
                    className={`m-0 mt-3 mb-2 d-block d-md-flex d-lg-flex  ${
                      changeMode && "text-white"
                    }`}
                  >
                    <b>CELL :</b>{" "}
                    <span className="ms-1">
                      {" "}
                      {country.cell.map((phone, i) => (
                        <span className="d-block" key={i}>
                          <a
                            className="text-decoration-none"
                            href={`tel:${phone}`}
                          >
                            {phone}
                          </a>
                        </span>
                      ))}
                    </span>
                  </p>
                  {country.tel && (
                    <p className={`m-0 mb-2  ${changeMode && "text-white"}`}>
                      <b>Tel : </b>
                      <a
                        className="text-decoration-none"
                        href={`tel:${country.tel}`}
                      >
                        {country.tel}
                      </a>
                    </p>
                  )}
                  <p className={`m-0  ${changeMode && "text-white"}`}>
                    <b>EMAIL :</b>{" "}
                    <a
                      className="text-decoration-none"
                      href={`mailto:${country.email}`}
                    >
                      {country.email}
                    </a>
                  </p>
                </div>
              ))}
            </div>
          </section>

          {/* slider section start */}
          {/* <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 5500,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper py-lg-4 py-3">
            {country.map((country, i) => (
              <SwiperSlide key={i}>
                <div className="font_size pt-lg-0 pt-md-0 pt-3 my-3">
                  <div className="d-flex align-items-center justify-content-lg-start justify-content-md-start justify-content-center">
                    <img
                      src={country.flag}
                      width={35}
                      height={27}
                      alt="avatar"
                    />
                    <p className={`m-0 ms-2 ${changeMode && "text-white"}`}>
                      <b>{country.name}</b>
                    </p>
                  </div>
                  <p
                    className={`mt-3 mb-2 text-justify  ${
                      changeMode && "text-white"
                    }`}>
                    {country.address}
                  </p>
                  <p
                    className={`m-0 mt-3 mb-2 d-block d-md-flex d-lg-flex  ${
                      changeMode && "text-white"
                    }`}>
                    <b>CELL :</b>{" "}
                    <span className="ms-1">
                      {" "}
                      {country.cell.map((phone, i) => (
                        <span className="d-block" key={i}>
                          {phone}
                        </span>
                      ))}
                    </span>
                  </p>
                  {country.tel && (
                    <p className={`m-0 mb-2  ${changeMode && "text-white"}`}>
                      <b>Tel : </b>
                      {country.tel}
                    </p>
                  )}
                  <p className={`m-0  ${changeMode && "text-white"}`}>
                    <b>EMAIL :</b> {country.email}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}
          {/* slider section end */}
        </Container>
      </section>
      {/* slider section */}

      {/* small footer section */}
      <Navbar className={`bg_header ${changeMode && "bg-black"}`}>
        <Container>
          <Nav className="w-100  justify-content-around align-items-center">
            <div></div>
            <p className="m-0 text-secondary">
              All Rights Reserved 2016-{year}. Developed by Aotrek .
            </p>
            <div className="d-flex">
              <Nav.Link href="http://www.facebook.com/aotrek" target="_blank">
                <FaFacebookF className="text-light fs-5" />
              </Nav.Link>
              <Nav.Link
                href="https://www.linkedin.com/company/travelbusinessportal/"
                target="_blank"
              >
                <GrLinkedinOption className="text-light fs-5 ms-3" />
              </Nav.Link>
            </div>
          </Nav>
        </Container>
      </Navbar>
    </footer>
  );
};

export default Footer;
