import React, { useReducer } from "react";
// import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useFirebase from "../../../hooks/useFirebase";

const Login = () => {
  const { logIn, loading } = useFirebase();
  const location = useLocation();
  const initializeState = {
    email: "",
    password: "",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "EMAIL":
        return {
          ...state,
          email: action.payload,
        };
      case "PASSWORD":
        return {
          ...state,
          password: action.payload,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initializeState);
  const handelSubmit = (e) => {
    e.preventDefault();
    logIn(state.email, state.password, location);
    console.log(state);
  };
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <h1>Login</h1>
      <form onSubmit={handelSubmit}>
        <label className="my-1" htmlFor="email">
          Email
        </label>
        <br />
        <input
          onChange={(e) => dispatch({ type: "EMAIL", payload: e.target.value })}
          required
          className="my-1"
          autoSave="off"
          autoFocus="off"
          autoComplete="off"
          type="email"
          name="email"
          id="email"
        />
        <br />
        <label className="my-1" htmlFor="password">
          Password
        </label>
        <br />
        <input
          onChange={(e) =>
            dispatch({ type: "PASSWORD", payload: e.target.value })
          }
          required
          className="my-1"
          autoSave="off"
          autoFocus="off"
          autoComplete="off"
          type="password"
          name="password"
          id="password"
        />
        <br />
        <button disabled={loading} className="btn btn-primary my-1">
          {loading ? "Loading..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default Login;
