/**
 * Title: Travel Business Portal New Version.
 * Description: Our Brand.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React from "react";
import "./SixSection.css";
// import brand from "../../../images/6th_section_bg/brand.png";
import tbp from "../../../images/Logo/TBP.png";
import ss from "../../../images/Logo/ss.png";
import dmc from "../../../images/Logo/DMC_Logo.png";
import { useContexts } from "../../../hooks/useContexts";
// import { Link } from "react-router-dom";
import { NavLink } from "react-bootstrap";
const SixSection = () => {
  const { changeMode } = useContexts();
  return (
    <section className={`bg_brand my-5 ${changeMode && "bg_brands"}`}>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-md-4 text_center_phn">
            <p className={`m-0 pt-2 ${changeMode && "text-white"} `}>OUR</p>
            <h1
              className={`fw-bold m-0 font_aotrek ${changeMode && "font_aot"}`}
            >
              BRANDS
            </h1>
          </div>
          <div
            className={`col-md-8 text-center py-5 ${
              changeMode && "bg-white rounded-1"
            }`}
          >
            {/* <img
              draggable={false}
              className="img-fluid w_50 w_100 py-5"
              src={brand}
              alt="avatar"
            /> */}
            <div className="d-flex align-items-center justify-content-around">
              <div>
                <NavLink href="https://travelbusinessportal.com/">
                  <img
                    className="img-fluid"
                    draggable={false}
                    width={130}
                    src={tbp}
                    alt="tbp"
                  />
                  <br />
                  <br />
                  <br />
                </NavLink>
                <NavLink href={"https://www.ssteamtravels.com/"}>
                  <img
                    className="img-fluid"
                    draggable={false}
                    width={300}
                    src={ss}
                    alt="tbp"
                  />
                </NavLink>
              </div>
              <div>
                <NavLink href={"https://tbpdmc.com/"}>
                  <img
                    className="img-fluid"
                    draggable={false}
                    width={200}
                    src={dmc}
                    alt="dmc"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixSection;
