import { useEffect } from "react";
import initializeFirebase from "../firebase/firebase.init";
import {
  onAuthStateChanged,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useState } from "react";
const useFirebase = () => {
  const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    console.log(user.email);
  initializeFirebase();
  const auth = getAuth();
  // login user
  const logIn = (email, password) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        //    setError(" ");
          
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setLoading(false));
    };
    
  // observer user State
  useEffect(() => {
    setLoading(true);
    const unsubscribed = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setUser({});
        setLoading(true);
      }
      //   setLoading(false);
    });
    return () => unsubscribed;
  }, [auth]);
    
  // logout
  const logOut = () => {
    setLoading(true);
    signOut(auth)
      .then(() => {
        setUser("");
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    logIn,
    logOut,
    user,
    loading,
  };
};

export default useFirebase;
