// import { useEffect } from "react";
import { createContext, useReducer } from "react";

export const CREATECONTEXT = createContext();
export const ContextProviders = ({ children }) => {
  const initializeState = {
    dark: false,
    changeHeader: false,
  };
  const reducer = (state, action) => {
    // console.log(action);
    // console.log(action.type);
    switch (action.type) {
      case "DARK_TOGGLE":
        return {
          ...state,
          dark: !state.dark,
        };
      case "SCROLL_UP":
        return {
          ...state,
          changeHeader: true,
        };
      case "SCROLL_DOWN":
        return {
          ...state,
          changeHeader: false,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initializeState);

  const { dark } = state;

  if (dark) {
    localStorage.setItem("theme", JSON.stringify({ darkMode: dark }));
  } else {
    localStorage.setItem("theme", JSON.stringify({ darkMode: dark }));
  }

  const handelScrollChange = () => {
    const scrollTop =
      window.pageYOff || window.document.documentElement.scrollTop;
    if (scrollTop > 0) {
      dispatch({ type: "SCROLL_UP" });
    } else {
      dispatch({ type: "SCROLL_DOWN" });
    }
  };
  window.addEventListener("scroll", handelScrollChange);

  const handelControl = (audios) => {
    const audio = new Audio(audios);
    audio.play();
    dispatch({ type: "DARK_TOGGLE" });
  };

  const changeMode = JSON.parse(localStorage?.getItem("theme")).darkMode;
  // console.log(changeMode);
  const allObj = {
    state,
    dispatch,
    handelControl,
    changeMode,
  };
// localStorage.setItem("Name",JSON.stringify({name:"swapon"}))
  return (
    <CREATECONTEXT.Provider value={allObj}>{children}</CREATECONTEXT.Provider>
  );
};
