/**
 * Title: Travel Business Portal New Version.
 * Description: Header Section.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React from "react";
import { Navbar, Nav, Container, Form } from "react-bootstrap";
import aotrek from "../../../images/Logo/Aotrek.png";
import { FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import audios from "../audio/audio.wav";
import "./Header.css";
import { useContexts } from "../../../hooks/useContexts";
import { useReducer } from "react";
const Header = () => {
  const {
    state: { changeHeader },
    handelControl,
    changeMode,
  } = useContexts();

  // console.log(changeHeader);

  const headerName = [
    {
      name: "Home",
      href: "home",
    },
    {
      name: "Service",
      href: "service",
    },
    {
      name: "About",
      href: "about",
    },
    {
      name: "Contact Us",
      href: "contact",
    },
  ];


  const initialState = {
    isDarkMode : JSON.parse(localStorage.getItem('isDarkMode')) || false
  }
  const reducer = (state,action)=>{
    switch (action.type) {
      case "TOGGLE_DARK_MODE":
      const newIsDarkMode = !state.isDarkMode;
      localStorage.setItem("isDarkMod",JSON.stringify(newIsDarkMode))
      return{
        ...state,
        isDarkMode: newIsDarkMode,
      }
      default:
        return state;
    }
  }
  const [mode,dispatch] = useReducer(reducer,initialState);
  // console.log(initialState)
  return (
    <header>
      <Navbar
        // data-aos="fade-down"
        className={`${changeMode && "bg-black"} ${
          changeHeader && "d-none"
        } bg_header`}
      >
        <Container>
          <Nav className="w-100 justify-content-md-between justify-content-lg-between justify-content-center align-items-center">
            <div className="d-flex d-lg-block d-md-block d-none"></div>
            <div className="ms-lg-5 ms-md-5 ms-0">
              <img draggable={false} src={aotrek} width={70} alt="avatar" />
              <p className="text-light text-center m-0">AOTREK</p>
            </div>
            <div className="d-lg-flex d-md-flex align-items-center d-none">
              <Nav.Link href="http://www.facebook.com/aotrek" target="_blank">
                <FaFacebookF className="text-light fs-5" />
              </Nav.Link>
              <Nav.Link
                href="https://www.linkedin.com/company/travelbusinessportal/"
                target="_blank"
              >
                <GrLinkedinOption className="text-light fs-5 ms-3" />
              </Nav.Link>
            </div>
          </Nav>
        </Container>
      </Navbar>
      <Navbar
        // data-aos="fade-up"
        // data-aos-anchor-placement="top-bottom"
        variant="dark"
        className={`bg_header_sec ${changeHeader && "position-fixed w-100"} ${
          changeMode && "bg_header_sec_mode"
        } `}
      >
        <Container>
          <Nav className="mx-auto font_size">
            {headerName.map((name, i) => (
              <Nav.Link
                key={i}
                className="mx-lg-2 mx-1 "
                href={`#${name.href}`}
              >
                {name.name}
              </Nav.Link>
            ))}
          </Nav>
          {/*
          <Form.Check
            onClick={() => handelControl(audios)}
            defaultChecked={changeMode}
            className="ms-2"
            type="switch"
            id="custom-switch"
          />
          <input
            type="checkbox"
            onChange={() => dispatch({ type: "TOGGLE_DARK_MODE" })}
            checked={JSON.parse(localStorage.getItem("isDarkMod"))}
          /> */}
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
