import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import notFound from '../../images/404.png'
const NotFound = () => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center my-5 py-5">
        <Helmet>
          <meta charSet="utf-8" />
          <title>😒 Page Not Found 😒</title>
        </Helmet>
        <h1 className="fw-bold">Page Not Found</h1>
        <img
          className="border rounded my-2"
          width={300}
          src={notFound}
          alt="avatar"
        />
        <Link to="/">
          <button className="btn btn-primary mt-2">Go To Back</button>
        </Link>
      </div>
    );
};

export default NotFound;