import "./App.css";
import "aos/dist/aos.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import { ContextProviders } from "./context/ContextProviders";

function App() {
  return (
    <>
      <ContextProviders>
        <RouterProvider router={router} />
      </ContextProviders>
    </>
  );
}

export default App;
