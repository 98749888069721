import Admin from "../Pages/admin/Admin/Admin";
import Login from "../Pages/admin/Login/Login";
import Home from "../Pages/Home/Home";
import NotFound from "../Pages/NotFound/NotFound";
import PrivateRoute from "../private/PrivateRoute";

const { createBrowserRouter } = require("react-router-dom");

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: (
      <PrivateRoute>
        <Admin />
      </PrivateRoute>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
