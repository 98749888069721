/**
 * Title: Travel Business Portal New Version.
 * Description: First Content Welcome.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React from "react";
import "./FirstSection.css";
import flight from "../../../images/1st_section_bg/flight.png";
import rail from "../../../images/1st_section_bg/rail.png";
import hotel from "../../../images/1st_section_bg/hotel.png";
import activity from "../../../images/1st_section_bg/activity.png";
import tour from "../../../images/1st_section_bg/tour.png";
import visa from "../../../images/1st_section_bg/visa.png";
import { useContexts } from "../../../hooks/useContexts";
import { Link } from "react-router-dom";

const FirstSection = () => {
  const flightProvide = [
    {
      name: "Flight",
      img:flight,
    },
    {
      name: "Rail",
      img:rail,
    },
    {
      name: "Hotel",
      img:hotel,
    },
    {
      name: "Activity",
      img:activity,
    },
    {
      name: "Tour",
      img:tour,
    },
    {
      name: "Visa",
      img:visa,
    },
  ]
  const companyName = "AOTREK";
  const { changeMode } = useContexts();
  // console.log(changeMode);
  return (
    <section className="bg_fr_sec container py-3" id="home">
      <div data-aos="zoom-out" data-aos-duration="1500">
        <p className={`m-0 pt-2 ${changeMode && "text-white"}`}>Welcome to</p>
        <h1 className={`fw-bold m-0 font_aotrek ${changeMode && "font_aot"}`}>
          {companyName}
        </h1>
        <p className={`m-0 ${changeMode && "text-white"}`}>We Provide...</p>
      </div>
      <div
        data-aos="zoom-out"
        data-aos-duration="1500"
        className="align-item-center aos-animate aos-init d-flex justify-content-between justify-content-lg-start justify-content-md-start py-3"
      >
        {flightProvide.map(({ name, img }, index) => (
          <div key={index} className="text-center me-lg-3 me-1">
            <a
              target="_blank"
              href="https://partner.travelbusinessportal.com/"
              className="list-unstyled"
            >
              <img
                draggable={false}
                width={40}
                height={40}
                src={img}
                alt="avatar"
              /></a>
              <p className={`${changeMode && "text-white"}`}>{name}</p>
            
          </div>
        ))}
      </div>
    </section>
  );
};

export default FirstSection;
