/**
 * Title: Travel Business Portal New Version.
 * Description: Who we are text.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React from "react";
import "./SecSection.css";
import imageSec from "../../../images/2nd_section_bg/alex-kotliarskyi-QBpZGqEMsKg-unsplash.png";
import { useContexts } from "../../../hooks/useContexts";
const SecSection = () => {
  const company = {
    name: "AOTREK",
    content: `We are eager, energetic and technologists. we love the journey as
              the destination, being part of the time but staying true to
              ourselves.Life at travel business portal is an endless
              adventure,full of opportunity,new experiences,meaningful bonds and
              rewarding relationships.`,
    img: imageSec,
  };
  const { name, content, img } = company;
  const { changeMode } = useContexts();
  // console.log(changeMode);
  return (
    <section
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="1500"
      id="home"
      className="container position-relative my-5"
    >
      <div
        className={`position-absolute custom_position ${
          changeMode && "custom_position_dark"
        } d-lg-block d-md-block d-none`}
      >
        <h1 className="fw-bold">{name}</h1>
      </div>
      <div
        className={`row align-items-center bg_gradient pt-lg-0 pt-md-0 pt-3 ${
          changeMode && "bg_gradi"
        }`}
      >
        <div className="col-md-9">
          <div className="ps-lg-5 ps-md-5 ps-0">
            <h1 className="fw-bold ps-lg-5 ps-md-5 ps-0 text-light stork_text">
              WHO WE ARE "
            </h1>
            <p className="ps-ls-5 ps-md-5 ps-0 text-light">{content}</p>
          </div>
        </div>
        <div className="col-md-3 p-0">
          <img draggable={false} className="img-fluid" src={img} alt="avatar" />
        </div>
      </div>
    </section>
  );
};

export default SecSection;
