/**
 * Title: Travel Business Portal New Version.
 * Description: Partners and client section.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React from "react";
import "./TheSection.css";
import imgGS from "../../../images/2nd_section_bg/saber.png";
import { Container } from "react-bootstrap";
import { useContexts } from "../../../hooks/useContexts";
const TheSection = () => {
  const { changeMode } = useContexts();
  // console.log(changeMode);
  return (
    <section className={`bg_colors pb-5 ${changeMode && "bg_colors_change"}`}>
      <Container>
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="text_center_phn">
              <p className={`m-0 pt-2 ${changeMode && "text-white"}`}>Our</p>
              <h1
                className={`fw-bold m-0 font_aotrek ${
                  changeMode && "font_aot"
                }`}
              >
                PARTNERS
              </h1>
              <p className={`m-0 ${changeMode && "text-white"}`}>
                & LONG-TIME CLIENTS
              </p>
            </div>
          </div>
          <div className={`col-md-8 text-center ${changeMode && 'bg-secondary rounded-1'}`}>
            <img
              draggable={false}
              className="img-fluid mw-75 w-100"
              src={imgGS}
              alt="avatar"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TheSection;
