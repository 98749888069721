/**
 * Title: Travel Business Portal New Version.
 * Description: Our Service.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React from "react";
import { useContexts } from "../../../hooks/useContexts";
import web from "../../../images/5th_section_bg/1st.png";
import api from "../../../images/5th_section_bg/2nd.png";
import server from "../../../images/5th_section_bg/3rd.png";
import flight from "../../../images/5th_section_bg/4th.png";
import "./FifthSection.css";
const FifthSection = () => {
  const ourService = {
    contentName: "Our Service",
    data: [
      {
        name: "WEB DEVELOPMENT",
        img: web,
      },
      {
        name: "API INTEGRATION",
        img: api,
      },
      {
        name: "WEB SERVER",
        img: server,
      },
      {
        name: "FLIGHT TICKET",
        img: flight,
      },
    ],
  };
  const {changeMode} = useContexts()
  return (
    <section className="container my-5 py-5" id="service">
      <div className="text-center">
        <p className={`m-0 pt-2 ${changeMode && "text-white"}`}>
          {ourService.contentName.split(" ")[0]}
        </p>
        <h1 className={`fw-bold m-0 font_aotrek ${changeMode && "font_aot"}`}>
          {ourService.contentName.split(" ")[1].toLocaleUpperCase()}
        </h1>
      </div>
      <div className="d-flex align-items-center justify-content-center text-center my-4 phn_service">
        {ourService.data.map(({ name, img }, index) => (
          <div key={index} className="mx-5">
            <img draggable={false} width={150} src={img} alt="avatar" />
            <p className={`${changeMode && "text-white"}`}>
              <b>
                {name.split(" ")[0]} <br /> {name.split(" ")[1]}
              </b>
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FifthSection;
