/**
 * Title: Travel Business Portal New Version.
 * Description: Contact Information.
 * Author: Swapon Saha.
 * Date: 18/07/2022.
 */
import React, { useRef, useState } from "react";
import contact from "../../../images/7th_section_bg/contact.png";
import "./SevenSection.css";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import { useContexts } from "../../../hooks/useContexts";

const SevenSection = () => {
  const form = useRef();
  const [send, setSend] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setSend(true);
    emailjs
      .sendForm(
        "service_3xi7wbr",
        "template_dmdgdmo",
        form.current,
        "y0KmKf_rS8vRFxvl7"
      )
      .then(
        (result) => {
          if (result.text) {
            swal(
              "Message Send Successfully",
              "You clicked the button!",
              "success"
            );
            e.target.reset();
            setSend(false);
          }
        },
        (error) => {
          if (error.text) {
            swal("Something Wrong", "You clicked the button!", "warning");
          }
        }
      );
  };
  const { changeMode } = useContexts();
  return (
    <section className="container mt-5 py-5" id="contact">
      <div className="row align-items-center">
        <div className="col-md-7">
          <h1 className={`fw-bold get_touch ${changeMode && "text-white"}`}>
            GET IN TOUCH
          </h1>
          <form ref={form} onSubmit={sendEmail}>
            <div
              className={`custom_ds_input d-lg-flex d-md-flex d-block ${
                changeMode && "custom_ds_inputs"
              }`}
            >
              <input
                required
                type="text"
                name="first_name"
                autoComplete="off"
                placeholder="First Name"
              />
              <input
                type="text"
                required
                name="last_name"
                autoComplete="off"
                placeholder="Last Name"
              />
            </div>
            <div
              className={`custom_ds_input d-lg-flex d-md-flex d-block ${
                changeMode && "custom_ds_inputs"
              }`}
            >
              <input
                type="email"
                required
                name="email"
                autoComplete="off"
                placeholder="Email"
              />
              <input
                required
                name="phone"
                autoComplete="off"
                type="tel"
                placeholder="Mobile number"
              />
            </div>
            <div
              className={`address_textarea ${changeMode && "address_textareas"}`}
            >
              <textarea
                required
                name="address"
                autoComplete="off"
                placeholder="Address"
              ></textarea>
            </div>
            {send ? (
              <input
                disabled
                className="btn btn-primary d-block mx-auto px-5"
                value="Send..."
                type="submit"
              />
            ) : (
              <input
                className="btn btn-primary d-block mx-auto px-5"
                value="Send"
                type="submit"
              />
            )}
          </form>
        </div>
        <div className="col-md-5 text-center d-lg-block d-md-block d-none">
          <img draggable={false} className="w-75" src={contact} alt="avatar" />
        </div>
      </div>
    </section>
  );
};

export default SevenSection;
